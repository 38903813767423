<template>
  <div class="form-wrapper">
    <b-row>
      <b-col cols="6">
        <div class="d-flex align-center">
          <h3 class="font-weight-bolder mb-0 d-flex align-items-center">
            {{ $t(OTHER_CHARGES_FORM_TITLE) }}
          </h3>
          <autosuggest
            ref="autocomplete"
            v-model="searchQuery"
            class="ml-1 flex-grow-1"
            :suggestions="suggestions"
            :input-props="getSearchInputProps()"
            v-bind="getSuggestProps()"
            :table-suggestion="true"
            :table-suggestion-configs="otherChanesTableAutoSuggestTableColumns"
            @input="fetchResults"
            @selected="selectHandler"
          />
        </div>
      </b-col>
    </b-row>

    <l-draggable-table-list-collector
      ref="lTableRef"
      :has-footer="true"
      :is-searchable="false"
      :module-name="MODULE_NAME"
      :fetched-data="fetchedRentals()"
      :table-columns="tableColumnsOtherChanes"
      :is-dragan-able="false"
      :table-suggestion-configs="otherChanesTableAutoSuggestTableColumns"
      :has-below-actions="true"
      class="rental-sales-table"
      :table-config-options="{
        endpoint: `${MODULE_NAME_CATALOG}/nonStocksActiveList`
      }"
      @getCollectedList="getCollectedList"
      @rowHoveredHandler="rowHoveredHandler"
      @rowUnHoveredHandler="rowUnHoveredHandler"
    >
      <template #cell(show_details)="{data}">
        <div
          v-if="data.id"
          class="d-flex p-0"
        >
          <feather-icon
            v-if="!isDeposit(data)"
            v-b-tooltip.noninteractive.click.bottom
            icon="LTrashIcon"
            class="lightIcon cursor-pointer border-dotted featherIcon"
            :title="$t('Remove')"
            size="30"
            style="padding: 4px"
            @click="remove({id: data.id})"
          />
          <feather-icon
            v-if="!isDeposit(data)"
            v-b-tooltip.noninteractive.click.bottom
            icon="LEyeIcon"
            size="30"
            style="padding: 4px"
            class="lightIcon cursor-pointer border-dotted featherIcon"
            :title="$t('Watch')"
            @click="navigateToProduct(data, $event)"
          />

        </div>
      </template>
      <template #cell(sku)="{data}">
        <div class="text-center">
          {{ data.sku ? data.sku : '-' }}
        </div>
      </template>
      <template #cell(name)="{data}">
        <div
          v-b-tooltip.noninteractive.hover
          class="cell-row-name"
          :title="data.name"
        >
          {{ data.name }}
        </div>
      </template>
      <template #cell(units)="{ data }">
        <increment-decrement-input
          v-if="data.id"
          ref="incrementDecrementInputItemsRental"
          class="d-flex justify-content-center"
          :item="data"
          style="height: 25px; width: 60px; margin: auto"
          item-key-name-for-amount="amount"
          :on-index-comparison="data.id === getTableRowIndex"
          :is-disabled-minus="data.amount === 1"
          @onIncrementDecrementAction="updateProductItemPrice()"
        >
          <template #switch-off-action>
            <div
              class="switch-off-action"
            >
              {{ data.amount ? data.amount : 1 }}
            </div>
          </template>
        </increment-decrement-input>
        <div
          v-else
          style="margin-left: 47%"
        >
          1
        </div>
      </template>
      <template
        #cell(rates)="{data}"
      >
        <span class="d-flex justify-content-center">
          {{ formatNumberToCurrencyView(data.price) }}
        </span>
      </template>
      <template
        #cell(discount)="{data}"
      >
        <span class="d-flex justify-content-center">
          {{ formatNumberToCurrencyView(data.discountPrice) }}
        </span>
      </template>
      <template
        #cell(subtotal)="{data}"
      >
        <span class="d-flex justify-content-center">
          {{ formatNumberToCurrencyView(data.subtotal) }}
        </span>
      </template>
      <template
        #cell(taxes)="{data}"
      >
        <span class="d-flex justify-content-center">
          {{ formatNumberToCurrencyView(data.tax) }}
        </span>
      </template>
      <template
        #cell(total)="{data}"
      >
        <span class="d-flex justify-content-center">
          {{ formatNumberToCurrencyView(data.totalPrice) }}
        </span>
      </template>
      <!--  Footer Cells    -->
      <template
        #foot(name)=""
      >
        <div>
          {{ $t('TOTAL') }}
        </div>
      </template>
      <template
        #foot(units)=""
      >
        <div class="text-center">
          {{ getItemKVOfProduct({ productKey: "amount" }) }}
        </div>
      </template>
      <template
        #foot(price)=""
      >
        <div class="text-center">
          {{ formatNumberToCurrencyView(getItemKVOfProduct({ productKey: "price" })) }}
        </div>
      </template>
      <template
        #foot(discount)=""
      >
        <div class="text-center">
          {{ formatNumberToCurrencyView(getItemKVOfProduct({ productKey: "discountPrice" })) }}
        </div>
      </template>
      <template
        #foot(subtotal)=""
      >
        <div class="text-center">
          {{ formatNumberToCurrencyView(getItemKVOfProduct({ productKey: "subtotal" })) }}
        </div>
      </template>
      <template
        #foot(taxes)=""
      >
        <div class="text-center">
          {{ formatNumberToCurrencyView(getItemKVOfProduct({ productKey: "tax" })) }}
        </div>
      </template>
      <template
        #foot(total)=""
      >
        <div class="text-center">
          {{ formatNumberToCurrencyView(getItemKVOfProduct({ productKey: "totalPrice" })) }}
        </div>
      </template>
    </l-draggable-table-list-collector>
  </div>
</template>

<script>
import {
  BCol, BRow,
  VBTooltip,
} from 'bootstrap-vue'
import { sumTotalAmountOfGivenArrayByProperty, formatNumberToCurrencyView, debounce } from '@core/utils/utils'
import LDraggableTableListCollector from '@/views/components/LDraggableTableListCollector.vue'
import * as configRentalSale from '@/views/main/orders/view/rental-sales/config'
import Autosuggest from '@/views/components/AutoSuggest/Autosuggest.vue'
import config from '../config'

export default {
  name: 'OtherChargesTable',
  components: {
    BRow,
    BCol,
    LDraggableTableListCollector,
    Autosuggest,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      getTableRowIndex: null,
      discount: 10,
      searchQuery: '',
      suggestions: [],
    }
  },
  computed: {
    orderItemsOtherCharges() {
      return this.$store.state[this.MODULE_NAME].orderItemsOtherChargesTable
    },
    eventInformationForm() {
      return this.$store.state[this.MODULE_NAME].eventInformationForm
    },
  },
  methods: {
    getSearchInputProps() {
      const defaultInputProps = {
        id: 'table-search',
        placeholder: 'Search...',
        class: 'form-control',
        name: 'table-search',
      }
      return {
        ...defaultInputProps,
      }
    },
    getSuggestProps() {
      const defaultProps = {
        'render-suggestion': this.renderSuggestion,
        'get-suggestion-value': this.getSuggestionValue,
      }
      return {
        ...defaultProps,
      }
    },
    renderSuggestion(suggestion) {
      return suggestion.item.cust_name_dba
    },
    getSuggestionValue(suggestion) {
      const { item } = suggestion

      return item.cust_name_dba
    },

    fetchResults(searchQuery) {
      this.search(searchQuery, this)
      this.$emit('onInput', searchQuery)
    },

    search: debounce((searchQuery, ctx) => {
      ctx.getSelectOptions(searchQuery)
    }, 500),
    getSelectOptions(params) {
      this.suggestions = []
      if (params && params.length > 2) {
        this.autoSuggestLoading = true

        this.$store.dispatch(`${this.MODULE_NAME_CATALOG}/nonStocksActiveList`, {
          search: params,
          f: {
            is_active: 1,
          },
        }).then(({ data }) => {
          const mappedData = data.data.data.map(item => ({
            ...item,
            name: item.cust_name_dba ? item.cust_name_dba : '---',
          }))
          this.suggestions = [{ data: mappedData }]
        }).finally(() => {
          this.autoSuggestLoading = false
        })
      }
    },
    selectHandler(suggest) {
      this.$refs.lTableRef.selectHandler(suggest)
      this.suggestions = []
    },

    navigateToProduct({ id }, e) {
      this.gotoNewPage({ name: 'home-catalog-general-info', params: { id } }, e, true)
    },
    updateProductItemPrice() {
      this.calculatePriceOfProducts({
        productsList: this.orderItemsOtherCharges,
        fieldName: {
          taxes: 'tax',
        },
        storeActionKey: 'SET_ORDER_ITEMS_OTHER_CHARGES_TABLE',
      })
    },

    rowHoveredHandler(rowData) {
      if (!this.$refs.incrementDecrementInputItemsRental) return
      const { id, amount } = rowData
      this.getTableRowIndex = id
      this.$refs.incrementDecrementInputItemsRental.setValue(amount ?? 1)
    },

    remove({ id }) {
      const orderItemsOtherCharges = this.orderItemsOtherCharges.filter(item => item.id !== id)

      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_OTHER_CHARGES_TABLE`, orderItemsOtherCharges)
    },

    getCollectedList(list) {
      const productsList = list.map(item => ({
        ...item,
        ...list[list.length - 1].id === item.id && {
          ...item,
          amount: item.amount ?? 1,
          price: item.rates?.[0]?.rate,
          discount: this.discount,
          tax: 0,
        },
      }))

      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_OTHER_CHARGES_TABLE`, productsList)
      this.updateProductItemPrice()
    },

    fetchedRentals() {
      return this.orderItemsOtherCharges
    },

    rowUnHoveredHandler() {
      this.getTableRowIndex = null
    },

    getItemKVOfProduct({ productKey }) {
      return this.sumTotalAmountOfGivenArrayByProperty(
        this.orderItemsOtherCharges,
        productKey,
      )
    },
    isDeposit(data) {
      return data.is_deposit ?? false
    },
  },
  setup() {
    const {
      tableColumnsOtherChanes,
      MODULE_NAME_CATALOG,
      MODULE_NAME,
      ORDER_ITEMS_RENTALS_TABLE_TITLE,
      OTHER_CHARGES_FORM_TITLE,
      otherChanesTableAutoSuggestTableColumns,
    } = config()

    const { calculatePriceOfProducts } = configRentalSale.default()

    return {
      MODULE_NAME,
      ORDER_ITEMS_RENTALS_TABLE_TITLE,
      OTHER_CHARGES_FORM_TITLE,
      tableColumnsOtherChanes,
      MODULE_NAME_CATALOG,
      sumTotalAmountOfGivenArrayByProperty,
      otherChanesTableAutoSuggestTableColumns,
      formatNumberToCurrencyView,
      calculatePriceOfProducts,
    }
  },
}
</script>
<style lang="scss">
.cell-row-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.item-note-input {
  height: 34px;
  outline: none;
  background-color: transparent;

  &:focus {
    box-shadow: none;
  }

  .rental-sales-table .l-tableList-collector__auto-suggest input {
    background-color: #fff !important;
  }

  .rental-sales-table thead th {
    padding: 5px !important;
  }

}

.rental-sales-table-start-date {
  margin-bottom: 0;
  gap: 10px;

  label {
    font-weight: bold;
  }

  .form-control {
    height: 26px !important;
  }

  .flatpickr-input--custom-style .flatpickr-input--calendar {
    top: 5px;
  }
}

.rental-start-time {
  display: flex;

  .form-control {
    height: 26px;
    padding: 0 5px !important;
    gap: 5px;
    align-items: center;
  }

  .btn {
    padding: 0 !important;
  }
}

#rental-start-time {
  padding: 0 !important;

  svg {

  }
}
</style>
